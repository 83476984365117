/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Image from 'next/image';
import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useState } from 'react';
import styles from '../styles/layout/dashboardHeader.module.scss';
import ListIcon from '@mui/icons-material/List';
import Lottie from 'lottie-react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { User_details } from '@/reduxtoolkit/UserSlice';
import { Cookies } from 'react-cookie';
import Link from 'next/link';
import { mediaPath, User_endpoint } from '@/api/endpoints';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useRouter } from 'next/router';
import { checkWindow } from '@/lib/functions/_storage.lib';
import useFirebase from '@/hooks/useFirebase';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import {
  Badge,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputBase,
  Paper,
  styled
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import SearchIcon from '@mui/icons-material/Search';

import SearchAnimation from '@/json/Lottie/Search.json';
import { useMutation, useQuery } from 'react-query';

import axiosInstance from 'Axios/axiosInstance';
import DataWrapper from '@/components/DataWrapper/DataWrapper';
import { useDebounce } from '@/hooks/useDebounce';
import { resquestCount } from '@/reduxtoolkit/BaseSlice';
const NoDataFoundStyled = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;
const DashboardHeader = () => {
  const cookie = new Cookies();
  const router = useRouter();
  const { logoutFromFirebase } = useFirebase();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [open22, setOpen22] = React.useState(false);
  const dispatch = useDispatch();
  const [pimage, setPimage] = useState('');

  const { User_details_data } = useSelector((s) => s.UserSlice);

  const [forumSearchValue, setForumSearchValue] = useState('');

  const debouncedMemberSearchValue = useDebounce(forumSearchValue, 300);

  const { isLoading, isError, data } = useQuery({
    queryKey: ['search', debouncedMemberSearchValue],
    queryFn: async () => {
      return axiosInstance
        .post(User_endpoint.searchforumlist, {
          title: forumSearchValue,
          baseId: User_details_data?.userbaselogs?.baseId
        })
        .then(({ data }) => {
          return data?.data || [];
        });
    },
    enabled: Boolean(forumSearchValue)
  });

  const updateSearchValue = useCallback(({ target: { value } }) => {
    // e.preventDefault()
    setForumSearchValue(value);
  }, []);

  const handleClickOpen22 = () => {
    setOpen22(true);
  };
  const handleClose22 = () => {
    setOpen22(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    dispatch(User_details());
  }, []);
  const Logout = () => {
    cookie.remove('token', { path: '/' });
    logoutFromFirebase();
    if (checkWindow()) {
      window.location.href = '/';
    }
  };
  useEffect(() => {
    if (User_details_data?.profile_image !== '') {
      setPimage(User_details_data?.profile_image);
    }
  }, [User_details_data?.profile_image]);

  const { resquestCount_data } = useSelector((s) => s.BaseSlice);

  useEffect(() => {
    dispatch(resquestCount());
  }, []);

  console.log(resquestCount_data, '1234');

  return (
    <div className={styles.dashboard_header}>
      <div className={styles.logo}>
        <Link href="/" className="tooltip">
          <Image
            src="/assets/images/logo.svg"
            alt="img"
            width="144px"
            height="128px"
          />
        </Link>
      </div>
      <ul>
        <li>
          <Tooltip title="Forum Menu">
            <button type="button" className={styles.round_btn}>
              <Link href="/dashboard/forum">
                <Image
                  src="/assets/images/dashboardMenu.svg"
                  alt="img"
                  width="21px"
                  height="18px"
                />
              </Link>
            </button>
          </Tooltip>
        </li>
        {/* <li>
          <button type="button" className={` ${styles.menu_btn}`}>
            <Link href="/dashboard/forum">
              <Image
                src="/assets/images/dashboardMsg.svg"
                alt="img"
                width="21px"
                height="18px"
              />
            </Link>
          </button>
        </li> */}
        <li>
          <Link href="/dashboard/profileedit">
            <Tooltip title="Edit Profile">
              {/* ${styles.active_menu} */}
              <button type="button" className={` ${styles.menu_btn} `}>
                <Image
                  src="/assets/images/dashboardUser.svg"
                  alt="img"
                  width="21px"
                  height="18px"
                />
              </button>
            </Tooltip>
          </Link>
        </li>
        <li>
          <Link href="/dashboard/forum/dashboardMember">
            <Tooltip title="My Profile">
              <button type="button" className={` ${styles.menu_btn}`}>
                <Image
                  src="/assets/images/dashboardGroup.svg"
                  alt="img"
                  width="21px"
                  height="18px"
                />
              </button>
            </Tooltip>
          </Link>
        </li>
        <li>
          <Link href="/dashboard/connections">
            <Tooltip title="Notification">
              <Badge badgeContent={resquestCount_data} color="primary">
                <button type="button" className={` ${styles.menu_btn}`}>
                  <Image
                    src="/assets/images/dashboardNotification.svg"
                    alt="img"
                    width="21px"
                    height="18px"
                  />
                </button>
              </Badge>
            </Tooltip>
          </Link>
        </li>
        <li>
          <Link href="/dashboard/searchbase">
            <Tooltip title="Location">
              <button type="button" className={` ${styles.menu_btn}`}>
                <Image
                  src="/assets/images/dashboardLocation.svg"
                  alt="img"
                  width="21px"
                  height="18px"
                />
              </button>
            </Tooltip>
          </Link>
        </li>
        <li className={`  ${styles.margin_left}`}>
          <Tooltip title="Search">
            <button
              onClick={handleClickOpen22}
              type="button"
              className={` ${styles.round_btn} ${styles.margin_left}`}
            >
              <Image
                src="/assets/images/dashboardSearch.svg"
                alt="img"
                width="21px"
                height="18px"
              />
            </button>
          </Tooltip>
        </li>
        <li>
          <Link href="/dashboard/connections">
            <Tooltip title="Notification">
              <Badge badgeContent={resquestCount_data} color="primary">
                <button type="button" className={` ${styles.menu_btn}`}>
                  <Image
                    src="/assets/images/dashboardNotification.svg"
                    alt="img"
                    width="21px"
                    height="18px"
                  />
                </button>
              </Badge>
            </Tooltip>
          </Link>
        </li>
        <Dialog
          open={open22}
          onClose={handleClose22}
          PaperProps={{ style: { borderRadius: '15px' } }}
        >
          <DialogTitle id="alert-dialog-title" style={{ height: '32rem' }}>
            <Paper
              elevation={0}
              component="form"
              sx={{
                p: '10px 20px',
                display: 'flex',
                alignItems: 'center',
                width: 725
              }}
            >
              <IconButton sx={{ p: '10px' }} aria-label="menu">
                <SearchIcon />
              </IconButton>
              <InputBase
                onChange={updateSearchValue}
                sx={{ ml: 1, flex: 1, p: '10px 20px' }}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
              />
              {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton> */}
            </Paper>

            <DataWrapper
              isError={isError}
              isLoading={isLoading}
              data={!forumSearchValue ? undefined : data} //issue was empty value returning all posts
              hasInitialState
              initialState={<Lottie animationData={SearchAnimation} loop />}
              // isRefetching={isRefetching}
              loader={<Lottie animationData={SearchAnimation} loop />}
            >
              {Array.isArray(data) &&
                data?.map((item, index) => {
                  return (
                    <ul className={styles.search_item_dash} key={index}>
                      <Link
                        href={`forumdetails/${item?.topic_id}?forum_id=${item?._id}`}
                      >
                        <li>
                          <div className={styles.search_item_list}>
                            <Image
                              src="/assets/images/person.svg"
                              alt="img"
                              width={40}
                              height={40}
                            />
                            <div className={styles.text_des}>
                              <h6>{item?.topic_title}</h6>

                              <p>active now a</p>
                            </div>
                          </div>
                        </li>
                      </Link>
                    </ul>
                  );
                })}

              {forumSearchValue &&
                Array.isArray(data) &&
                data?.length === 0 && (
                  <NoDataFoundStyled>No Data Found</NoDataFoundStyled>
                )}
            </DataWrapper>
          </DialogTitle>
          {/* <DialogContent> */}

          {/* </DialogContent> */}
        </Dialog>

        {/* <li>
          <Link href="/dashboard/notification">
            <Tooltip title="Notification">
              <button type="button" className={` ${styles.round_btn}`}>
                <Image
                  src="/assets/images/dashboardNotification.svg"
                  alt="img"
                  width="21px"
                  height="18px"
                />
              </button>
            </Tooltip>
          </Link>
        </li> */}
        {/* <li>
          <button type="button" className={` ${styles.round_btn}`}>
            <Image
              src="/assets/images/dashboardChat.svg"
              alt="img"
              width="21px"
              height="18px"
            />
          </button>
        </li> */}
      </ul>

      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.menuPfBtn}
      >
        <div className={styles.userProfile}>
          <div className={styles.userImg}>
            {/* {User_details_data?.profile_image === '' ? (
              <Avatar
                src="/assets/images/userImg.svg"
                // src={`${mediaPath}/user/profile_pic/${pimage}`}
                alt="img"
                width={100}
                height={100}
              />
            ) : (
              <Avatar
                // src="/assets/images/userImg.svg"
                src={`${mediaPath}/user/profile_pic/${pimage}`}
                alt="img"
                width={100}
                height={100}
              />
            )} */}

            {pimage === '' ? (
              <img
                style={{ height: '37px', width: '51px' }}
                src="https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"
              ></img>
            ) : (
              <Avatar
                // src="/assets/images/userImg.svg"
                src={`${mediaPath}/user/profile_pic/${pimage}`}
                alt="img"
                width={100}
                height={100}
              />
            )}
          </div>

          <div className={styles.userName}>
            {/* <h5>{User_details_data?.fullName}</h5> */}
            <h5>{User_details_data?.username}</h5>
            <h6>{User_details_data?.role?.roleDisplayName}</h6>
          </div>
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            router.push('/dashboard/profileedit');
          }}
        >
          <ListItemIcon>
            <Image
              src="/assets/images/dashboardUser.svg"
              alt="img"
              width="21px"
              height="18px"
            />
          </ListItemIcon>
          <ListItem>
            <ListItemText primary="My Profile" />
          </ListItem>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            Logout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItem>
            <ListItemText primary="Logout" />
          </ListItem>
        </MenuItem>
      </Menu>
      <div className={styles.mobile_dash_header}>
        <button
          type="button"
          className={styles.menu_btn}
          onClick={handleDrawerToggle}
        >
          <ListIcon />
        </button>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 }
            }}
            className={styles.mobile_dash_sidebar}
          >
            <div>
              <ul>
                <li>
                  <button type="button" className={styles.round_btn}>
                    <Link href="/dashboard/forum">
                      <Image
                        src="/assets/images/dashboardMenu.svg"
                        alt="img"
                        width="21px"
                        height="18px"
                      />
                    </Link>
                  </button>
                </li>
                <li>
                  <button type="button" className={` ${styles.menu_btn}`}>
                    <Link href="/dashboard/forum">
                      <Image
                        src="/assets/images/dashboardMsg.svg"
                        alt="img"
                        width="21px"
                        height="18px"
                      />
                    </Link>
                  </button>
                </li>
                <li>
                  <button type="button" className={` ${styles.menu_btn}`}>
                    <Link href="/dashboard/profileedit">
                      <Image
                        src="/assets/images/dashboardUser.svg"
                        alt="img"
                        width="21px"
                        height="18px"
                      />
                    </Link>
                  </button>
                </li>
                <li>
                  <button type="button" className={` ${styles.menu_btn}`}>
                    <Link href="/dashboard/forum/dashboardMember">
                      <Image
                        src="/assets/images/dashboardGroup.svg"
                        alt="img"
                        width="21px"
                        height="18px"
                      />
                    </Link>
                  </button>
                </li>
                <li>
                  <button type="button" className={` ${styles.menu_btn}`}>
                    <Link href="/dashboard/connections">
                      <Image
                        src="/assets/images/dashboardNotification.svg"
                        alt="img"
                        width="21px"
                        height="18px"
                      />
                    </Link>
                  </button>
                </li>
                <li>
                  <button type="button" className={` ${styles.menu_btn}`}>
                    <Link href="/dashboard/searchbase">
                      <Image
                        src="/assets/images/dashboardLocation.svg"
                        alt="img"
                        width="21px"
                        height="18px"
                      />
                    </Link>
                  </button>
                </li>
                <li className={`  ${styles.margin_left}`}>
                  <button
                    onClick={handleClickOpen22}
                    type="button"
                    className={` ${styles.round_btn} ${styles.margin_left}`}
                  >
                    <Image
                      src="/assets/images/dashboardSearch.svg"
                      alt="img"
                      width="21px"
                      height="18px"
                    />
                  </button>
                </li>
                <li>
                  <button type="button" className={` ${styles.round_btn}`}>
                    <Link href="/dashboard/notification">
                      <Image
                        src="/assets/images/dashboardNotification.svg"
                        alt="img"
                        width="21px"
                        height="18px"
                      />
                    </Link>
                  </button>
                </li>
                <li>
                  <button type="button" className={` ${styles.round_btn}`}>
                    <Image
                      src="/assets/images/dashboardChat.svg"
                      alt="img"
                      width="21px"
                      height="18px"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </Drawer>
        </Box>
      </div>
    </div>
  );
};

export default DashboardHeader;
