import Loader from '@/ui/Loader/Loader';
import React from 'react';
const DataWrapper = ({
  isLoading,
  isError,
  isRefetching,
  children,
  loader,
  initialState,
  data,
  hasInitialState = false
}) => {
  if (data === undefined && !isLoading && hasInitialState) {
    return initialState;
  }

  if (isLoading) {
    if (loader) {
      return loader;
    } else {
      return <Loader />;
    }
  }

  if (isError) {
    return <h1>Something went wrong</h1>;
  }

  if (isRefetching) {
    return <h4>Refetching....</h4>;
  }

  return children;
};

export default DataWrapper;
