import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const Loader = ({ color = 'white', size = 25 }) => {
  return (
    <div>
      <CircularProgress
        size={size}
        sx={{
          color
        }}
      />
    </div>
  );
};

// Loader.propTypes = {
//   color:PropTypes.string,
//     fontSize:PropTypes.number
// }

export default Loader;
